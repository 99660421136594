@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    margin: auto;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* background-image: url('assets/200.webp'); */
    /* background-size: cover; */
    background-color: #fafafa;
    /* background-color: rgb(13, 16, 16); */
    /* background-blend-mode: color-dodge; */
    /* background-position: top; */
    scrollbar-color: auto;
}

::selection {
    background: rgb(224 231 255);
    /* WebKit/Blink Browsers */
    color: #080808;
}

::-moz-selection {
    background: rgb(165 180 252);
    /* Gecko Browsers */
    color: #080808;
}

@layer base {
    h1 {
        @apply text-4xl font-medium;
    }

    h2 {
        @apply text-3xl font-medium;
    }

    h3 {
        @apply text-xl font-medium;
    }

    h4 {
        @apply text-lg font-medium;
    }

    h5 {
        @apply text-lg font-medium;
    }

    blockquote {
        @apply p-4 border-l-4 border-gray-300 bg-gray-100;
    }

    ul {
        @apply ml-6 list-disc;
    }

    li {
        @apply my-1;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.active {
    text-decoration: wavy;
    text-decoration-color: #9f9f9f;
    text-decoration-line: underline;
}


.lightbox {
    /* Default to hidden */
    display: none;

    /* Overlay entire screen */
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /* A bit of padding around image */
    padding: 1em;

    /* Translucent background */
    background: rgba(0, 0, 0, 0.8);
}

.lightbox:target {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    /* Optional: Adds background overlay */
    z-index: 9999;
    display: flex;
    justify-content: center;
    /* Horizontally center */
    align-items: center;
    /* Vertically center */
}

.lightbox span {
    /* Set width and height constraints to fit within lightbox */
    max-width: 40vw;
    /* Ensure it doesn't exceed viewport width */
    max-height: 80vh;
    /* Ensure it doesn't exceed viewport height */
    display: block;

    /* Size and position background image */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    /* Ensure it scales while keeping aspect ratio */
    object-fit: contain;
    /* Adjust the image scaling */
}